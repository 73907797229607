import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink, useParams } from 'react-router-dom';
import Loader from "../components/Loader";
import * as Constants from '../components/Constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const Subcategory = () => {
  const [pcategory, setPcategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const params = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`${Constants.LARAVEL_API_URL}/getSubCategory/${params.category_slug}/${params.subcategory_slug}`).then(res => {
        if (res.data.status === 200) {
          setLoading(false)
          setCategory(res.data.subcategory)
          setPcategory(res.data.pcategory)
        }
    });
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`${Constants.LARAVEL_API_URL}/getProductsByCategory/${params.subcategory_slug}`).then(res => {
        if (res.data.status === 200) {
          setLoading(false)
          setProducts(res.data.products)
        }
    });
  }, [params]);

  let allProducts = products.map((product) => (
    <Link to={`/product/${product.slug}`} className="productItem">
      <figure>
          <img 
          src={`${Constants.LARAVEL_URL}/assets/images/products/${product.image}`} 
          alt={product.name} 
          className="productItemImg"
          />
          <h2>{product.name}</h2>
      </figure>
    </Link>
  ));

  let getSubcategories = category.children && category.children.map((subcategory) => (
    <Link to={`/catalog/${pcategory.slug}/${category.slug}/${subcategory.slug}`} className="subcategoryItem" key={subcategory.id}>
        <figure>
            <img src={`${Constants.LARAVEL_URL}/assets/images/categories/${subcategory.image}`} alt={subcategory.name} className="productItemImg" />
        </figure>
        {subcategory.name}
    </Link>
  ));

  const {t} = useTranslation();

  return (
    <>
    <main>
        <Header />
        <div className="container">
            <h1 className="commonTitle">{loading ? <Loader width={30} height={30} /> : category.name}</h1>
            <div className="commonBreadcrumbs">
                <ul>
                    <li><NavLink to="/"><i className="fa-solid fa-house-chimney"></i></NavLink></li>
                    /
                    <li><NavLink to="/catalog">{t('catalog_page_title')}</NavLink></li>
                    /
                    <li><NavLink to={`/catalog/${pcategory.slug}`}>{loading ? <Loader width={10} height={10} /> : pcategory.name } </NavLink></li>
                    /
                    <li>{loading ? <Loader width={10} height={10} /> : category.name }</li>
                </ul>
            </div>
            <div className="subcategory__output">
            {category.description ? <div className="subcategory__content">{parse(category.description)}</div> : ''}

              <div className="productsOutput">
                {loading ? <Loader width={400} height={400} /> : allProducts}
              </div>

              <div className="categoryOutput">
                {loading ? <Loader width={400} height={400} /> : getSubcategories}
              </div>
            </div>

        </div>
        </main>
        <Footer />
    </>
  )
}

export default Subcategory
